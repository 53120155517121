export default {
    en: {
        productHeading: { 
            value: "Contact Us"
        },
        aboutCard: { 
            heading: "About Square Garden",
            text: "Square Garden is a small, very young program and is still under development. If you have any suggestions for changes or further ideas we would be happy to hear from you. You can simply leave us a message using the following contact form."
        },
        contactCard: { 
            heading:"We are looking forward to your ideas!",
        },
        form: {
            email: { 
                value: "Email adress"
            },
            message: { 
                value: "Message"
            }
        },
        send: {
            value: "Send",
        }
    },
    de: {
        productHeading: { 
            value: "Kontakt"
        },
        aboutCard: { 
            heading: "Über Square Garden",
            text: "Square Garden ist ein kleines, sehr junges Programm und steht noch weiter in der Entwicklung. Falls sie Anregungen für Veränderungen oder weitere Ideen haben würden wir uns über eine Nachricht von ihnen freuen. Sie können uns einfach über folgendes Kontaktformular eine Nachricht hinterlassen."
        },
        contactCard: { 
            heading:"Wir freuen uns auf ihre Ideen!",
        },
        form: {
            email: { 
                value: "Email adresse"
            },
            message: { 
                value: "Nachricht"
            }
        },
        send: {
            value: "Senden",
        }
    }
}