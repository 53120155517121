export default {
    namespaced: true,
    state: {
        //The currently logged in user
        lang: "de",
    },
    mutations: {
        UPDATE_LANG(state, lang) {
            state.lang = lang;
        }
    },
    actions: {
        change_lang({ commit }, lang) {
            commit("UPDATE_LANG", lang);
        }
    },
    getters: {
        getLang: (state) => {
            return state.lang
        }
    }
}