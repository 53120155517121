export default {
    en: {
        heading: {
            weather: "Weather location",
            home: "Home location",
            lunar: "Lunar Calender",
        },
        weather: {
            location: "Location",
            lat: "Lat",
            lon: "Lon",
            loc_name: "Location",
            current_data: "Current Data",
            temp: "Temperature",
            wind_speed: "Wind",
            wind_dir: "Wind direction",
            pressure: "Air pressure",
            precip: "Precipitation",
            humidity: "Humidity",
            condition: "Condition",
            air: "Air data",
        },
        home: {
            heading: "Home location",
            home_pos: "Home location",
            temp: "Temperature",
            wind_speed: "Wind",
            wind_dir: "Wind direction",
            pressure: "Air pressure",
            precip: "Precipitation",
            humidity: "Humidity",
            condition: "Condition",
            air: "Air data",
            lunar: "Lunar data",
            srise: "Sunrise",
            sset: "Sunset",
            mrise: "Moonrise",
            mset: "Moonset",
            mphase: "Moon phase",
            millumination: "Moon illumination",
        },
        lunar: {
            heading: "Lunar calender",
            days: { 
                today: "Today",
                tomorrow: "Tomorrow",
                at: "Day after tomorrow",
            },
            date: "Date",
            srise: "Sunrise",
            sset: "Sunset",
            mrise: "Moonrise",
            mset: "Moonset",
            mphase: "Moon phase",
            millumination: "Moon illumination",
        },
        geolocation: {
            active: "Activate permanent geolocation for better results: "
        }
    },
    de: {
        heading: {
            weather: "Wetter Position",
            home: "Wetter Zuhause",
            lunar: "Mondkalender",
        },
        weather: {
            location: "Standort",
            lat: "Lat",
            lon: "Lon",
            loc_name: "Position",
            current_data: "Aktuelle Daten",
            temp: "Temperatur",
            wind_speed: "Wind",
            wind_dir: "Windrichtung",
            pressure: "Luftdruck",
            precip: "Niederschlag",
            humidity: "Luftfeuchtigkeit",
            condition: "Bedingung",
            air: "Luftdaten",
        },
        home: {
            heading: "Zuhause",
            home_pos: "Position",
            temp: "Temperatur",
            wind_speed: "Wind",
            wind_dir: "Windrichtung",
            pressure: "Luftdruck",
            precip: "Niederschlag",
            humidity: "Luftfeuchtigkeit",
            condition: "Bedingung",
            air: "Luftdaten",
            lunar: "Monddaten",
            srise: "Sonnenaufgang",
            sset: "Sonnenuntergang",
            mrise: "Mondaufgang",
            mset: "Monduntergang",
            mphase: "Mondphase",
            millumination: "Mondbeleuchtung",
        },
        lunar: {
            heading: "Mondkalender",
            days: { 
                today: "Heute",
                tomorrow: "Morgen",
                at: "Übermorgen",
            },
            date: "Datum",
            srise: "Sonnenaufgang",
            sset: "Sonnenuntergang",
            mrise: "Mondaufgang",
            mset: "Monduntergang",
            mphase: "Mondphase",
            millumination: "Mondbeleuchtung",
        },
        geolocation: {
            active: "Aktivieren der permanenten Geoposition für bessere Ergebnisse: "
        }
    }
}