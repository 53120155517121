import Vue from "vue";
import VueRouter from "vue-router";

//for auth
import {} from "../db/auth.js";
import store from "../store/index.js";

//Components
const Home = () => import("../views/homepageviews/Home.vue");
const Dashboard = () => import("../views/Dashboard.vue");
const Content = () => import("../views/Content.vue");
const Profile = () => import("../views/Profile.vue");
const Garden = () => import("../views/Garden.vue");
const Season = () => import("../views/Season.vue");
const Weather = () => import("../views/Weather.vue");
const LunarCalender = () => import("../views/LunarCalender.vue");
const Tutorial = () => import("../views/Tutorial.vue");
const Login = () => import("../views/homepageviews/Login.vue");
const Contact = () => import("../views/homepageviews/Contact.vue");
const Product = () => import("../views/homepageviews/Product.vue");
const Register = () => import("../views/homepageviews/Register.vue");
const NotFound = () => import("../views/NotFound.vue");

Vue.use(VueRouter);

/*
Let’s change the current, wasteful approach of loading all the code needed to render all our routes upfront, 
by configuring Vue Router to utilize the power of webpacks code splitting feature.

Instead of immediately importing our components like we did before by using import Hello from '@/components/Hello'; 
we’re now using the new dynamic import JavaScript feature const Hello = () => import('@/components/Hello');. 
Using dynamic imports, triggers webpack to split the code and create separate chunks for every dynamically imported file.
*/

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/content",
    name: "Content",
    component: Content,
    meta: {
      requiresAuth: true,
    },
  },
  /*{
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    }
  },*/
  {
    path: "/garden/:id",
    name: "SingleGarden",
    component: Garden,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/seasons",
    name: "Seasons",
    component: Season,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: Tutorial,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/weather",
    name: "Weather",
    component: Weather,
    meta: {
      requiresAuth: true,
    },
  },
  /*{
    path: "/lunarCalender",
    name: "LunarCalender",
    component: LunarCalender,
    meta: {
      requiresAuth: true,
    }
  },*/
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const currentUserAuth = store.state.user.authStatus;

  if (requiresAuth && !currentUserAuth) {
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else if (!requiresAuth && currentUserAuth) {
    next("/dashboard");
  } else if (!requiresAuth && !currentUserAuth) {
    next();
  } else {
    next();
  }
});

export default router;
