export default {
    en: {
        card: { 
            yield: "Yield Amount",
            end: "Closed",
            fertilizers: "Fertilizers"
        }
    },
    de: {
        card: { 
            yield: "Ertrag",
            end: "Geschlossen",
            fertilizers: "Dünger",
        }
    }
}