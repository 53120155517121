export default {
    en: {
        headings: { 
            menu: "Menu",
            endings: "Seasonal endings",
            logout: "Logout"
        },
        menu: {
            home: "Home",
            content: "Content",
            tutorial: "Help / Tutorial",
            language: "Language",
            weather: "Weather",
            lunar: "Lunar calendar"
        },
        endings: {
            title: "Season endings"
        },
        logout: {
            title: "Logout"
        }
    },
    de: {
        headings: { 
            menu: "Menü",
            endings: "Saison Abschlüsse",
            logout: "Logout"
        },
        menu: {
            home: "Home",
            content: "Material",
            tutorial: "Hilfe / Lernprogramm",
            language: "Sprache",
            weather: "Wetter",
            lunar: "Mondkalender"
        },
        endings: {
            title: "Abschlüsse"
        },
        logout: {
            title: "Logout"
        }
    }
}