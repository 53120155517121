export default {
    en: {
        productHeading: { 
            value: "Product"
        },
        subheading: { 
            heading: "What is Square Graden?",
            text: "Square Garden is a tool, which hepls you to organize and strukture your gardening."
        },
        homeCard: { 
            heading: "The Home Area",
            text: "The Home area is the area where you can manage your gardens or areas. You can create new gardens, edit or delete them. It is also possible to create fertilizers that you want to use and set reminders for those."
        },
        gardenAreaCard: { 
            heading:"The Garden Area",
            text:"In the garden area you can create the fields of your garden. The length that will define these fields you enter directly when creating your garden. For each field you can define a plant species.",
        },
        gardenMenuCard: { 
            heading:"The Graden Menu",
            text:"The garden menu opens for each field of your garden. There, depending on the type of plant you have selected, you can choose a plant. You can also define the fertilizer you want to use for the plant and a timer.",
        },
        seasonCard: { 
            heading:"Seasonal endings",
            text:"After you have completed a field for a season, you can compare your yields and fertilizer of each field in this window.",
        },
        register: {
            text: "Register now! 🍎",
            value: "Register",
        }
    },
    de: {
        productHeading: { 
            value: "Produkt"
        },
        subheading: { 
            heading: "Was ist Square Garden?",
            text: "Square Garden ist ein Tool, dass sie bei der Organisation und Strukturierung ihres Gartens helfen soll."
        },
        homeCard: { 
            heading: "The Home Area",
            text: "Der Home Bereich ist der Bereich, in dem sie ihre Gärten oder Gartenbereiche verwealten können. Sie können neue Gärten erstellen, bearbeiten oder löschen. Ebenso ist es hier möglich Dünger zu erstellen, welche sie verwenden möchten."
        },
        gardenAreaCard: { 
            heading:"Der Garten Bereich",
            text:"Im Gartenbereich können werden die Felder ihres Gartens erstellt. Die Länge die diese Felder definieren sollen geben sie bei der Erstellung ihres Gartens direkt ein. Für jedes Feld können sie eine Pflanze, die eine Pflanzenart festlegen.",
        },
        gardenMenuCard: { 
            heading:"Das Garten Menu",
            text:"Das Gartenmenu öffnet sich für jedes Feld ihres Gartens. Dort können sie, je nachdem welche Pflanzenart sie ausgewählt haben, eine Pflanze aussuchen. Ebenso können sie dort den Dünger, welchen sie für die Pflanze verwenden möchten, sowie einen Timer definieren.",
        },
        seasonCard: { 
            heading:"Saison Abschlüsse",
            text:"Nachdem sie ein Feld für eine Saison abgeschlossen haben können sie in diesem Fenster ihre Erträge und und Dünger der einzelnen Felder vergleichen.",
        },
        register: {
            text: "Jetzt Anmelden 🍎",
            value: "Registrieren",
        },
    }
}