
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'; 

const firebaseConfig = {
    apiKey: "AIzaSyCRmHuBBRWt0sklf0I_uwrrVMy9gTDmpA4",
    authDomain: "square-garden.firebaseapp.com",
    projectId: "square-garden",
    storageBucket: "square-garden.appspot.com",
    messagingSenderId: "240051652623",
    appId: "1:240051652623:web:8f6c9b31207e394e027b7a"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

//disabling deprecated features
db.settings({
    timestampsInSnapshots: true,
})

// The default cache size threshold is 40 MB. Configure "cacheSizeBytes"
// for a different threshold (minimum 1 MB) or set to "CACHE_SIZE_UNLIMITED" to disable clean-up.
firebase.firestore().settings({
    cacheSizeBytes: 124000000
});

//enabling persistence
db.enablePersistence()
    .then(() => {
        console.log("Persistence enabled!");
        //console.log("You have offline capabilities!");
    })
    .catch(err => {
        if(err.code === "failed-precondition") {
            console.log("There is another Tab open, be sure to only open this App once to have syncronized Data!");
        } else if(err.code === "unimplemented") {
            console.log("We are very sorry but your current browser does not support all of the features required to enable offline persistence!");
        }
    })

//

//allowing offline mode for indexDB
//db.enablePersistence({
//    experimentalTabSynchronization:true
//})
/* //-> if lines above dont work
db.enablePersistence()
    .then(() => {
        console.log("persistence enabled!");
    })
    .catch(err => {
        if(err.code == "failed-precondition"){
            //probably multiple tabs open at once
            console.log("persistence failed");
        } else if(err.code == "unimplemented") {
            //Browser does not support it
            console.log("persistence is not available");
        }
    });
*/

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

export const auth = firebase.auth();

//i don't need right now
//const functions = firebase.functions();
export default db;