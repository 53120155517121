import Vue from 'vue'
import Vuex from 'vuex'

import user from "./user";
import reminder from "./reminder";
import geolocation from "./geolocation";
import lang from "./lang";

//for persistante state
import createPersistedState from 'vuex-persistedstate'
//import * as mut from "./mutation_types.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMenu: false,
    showGarden: false,
    panelColor: null,
    showSquareMenu: false,
    plantTypes: [
      "fruit",
      "herb",
      "flower",
      "tree",
      "vegetable",
      "succulent",
      "bush",
      "delete",
    ],
    //important for seasonendings
    currentGardenTitle: "",
    //data for the current SquareMenu
    currentFertilizer: null,
    currentPlantType: null,
    currentPlantSpec: null,
    currentSquareId: null,
  },
  mutations: {
    SHOW_MENU(state) {
      state.showMenu = !state.showMenu;
    },
    SHOW_NEW_GARDEN(state) {
      state.showGarden = !state.showGarden;
    },
    DELETE_PANELCOLOR(state) {
      state.panelColor = null;
    },
    SET_PANELCOLOR(state, color) {
      state.panelColor = color;
    },
    SHOW_SQUARE_MENU(state, payload) {
      state.showSquareMenu = !state.showSquareMenu;
      state.currentPlantType = payload.type;
      state.currentFertilizer = payload.fertilizer;
      state.currentPlantSpec = payload.specification;
      state.currentSquareId = payload.id;
    },
    SET_GARDENTITLE(state, gardenTitle){
      state.currentGardenTitle = gardenTitle;
    }
  },
  actions: {
  },
  modules: {
  },
  methods: {
  },
  getters: {
    getPanelColor: (state) => {
      return state.panelColor;
    }
  },
  modules: {
    user: user,
    reminder: reminder,
    geolocation: geolocation,
    lang: lang,
  },
  plugins:[
      createPersistedState({
          paths: ['user', 'geolocation', 'lang'],
      })
  ]
})
