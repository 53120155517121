import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import store from './store'
import VueMeta from "vue-meta"

import VueI18n from "vue-i18n";

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(VueMeta);
Vue.use(VueI18n);

//importing the languagepacks for the application is
import homelangpack from "./multilang/homepage/home_langpack.js";
import navlangpack from "./multilang/homepage/nav_langpack.js";
import productlangpack from "./multilang/homepage/product_langpack.js";
import registerlangpack from "./multilang/homepage/register_langpack.js";
import loginlangpack from "./multilang/homepage/login_langpack.js";
import contactlangpack from "./multilang/homepage/contact_langpack.js";

import dashboardlangpack from "./multilang/application/dashboard_langpack.js";
import newGardenlangpack from "./multilang/application/newGardenMenu_langpack.js";
import menulangpack from "./multilang/application/menu_langpack.js";
import endingslangpack from "./multilang/application/endings_langpack.js";
import gardenlangpack from "./multilang/application/garden_langpack.js";
import tutoriallangpack from "./multilang/application/tutorial_langpack.js";
import weatherlangpack from "./multilang/application/weather_langpack.js";

//Has to be called messages
const messages = {
  en: { 
    //homepage
    nav: navlangpack.en,
    home: homelangpack.en,
    product: productlangpack.en,
    register: registerlangpack.en,
    login: loginlangpack.en,
    contact: contactlangpack.en,
    //application
    dashboard: dashboardlangpack.en,
    newGardenMenu: newGardenlangpack.en, //not used yet because of forms
    menu: menulangpack.en,
    endings: endingslangpack.en,
    garden: gardenlangpack.en,
    tutorial: tutoriallangpack.en,
    weather: weatherlangpack.en,
  },
  de: { 
    //homepage
    nav: navlangpack.de,
    home: homelangpack.de,
    product: productlangpack.de,
    register: registerlangpack.de,
    login: loginlangpack.de,
    contact: contactlangpack.de,
    //application
    dashboard: dashboardlangpack.de,
    newGardenMenu: newGardenlangpack.de,
    menu: menulangpack.de,
    endings: endingslangpack.de,
    garden: gardenlangpack.de,
    tutorial: tutoriallangpack.de,
    weather: weatherlangpack.de,
  },
}

//This is config which has to stay here for the i18n plugin --> wird dann unten verwendet
const lang = store.getters["lang/getLang"];

const i18n = new VueI18n({
  locale: lang,
  messages
})




new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
}).$mount('#app')

