export default {
    namespaced: true,
    state: {
        //The currently logged in user
        authStatus: null,
    },
    mutations: {
        UPDATE_USER_STATUS(state, newStatus) {
            state.authStatus = newStatus;
        },
        UPDATE_LOGOUT_STATUS (state, newState){
            state.loggedOut = newState;
        }
    },
    actions: {
        change_auth_status({ commit }, auth_status) {
            commit("UPDATE_USER_STATUS", auth_status);
        }
    },
    getters: {
        getUserStatus: (state) => {
            return state.authStatus
        }
    }
}