export default {
    en: {
        product: { 
            value: "Application"
        },
        contact: { 
            value: "Contact"
        },
        register: { 
            value: "Register"
        }
    },
    de: {
        product: {
            value: 'Produkt'
        },
        contact: {
            value: 'Kontakt'
        },
        register: { 
            value: "Registrieren"
        }
    }
}