export default {
    en: {
        heading: {
            value: "Tutorial"
        },
        subheading: {
            text: "In this tutorial we will walk through the simple creation process of creating a garden and fertilizer, organizing your garden and selecting plants. We will do this on the example of an herb field."
        },
        home: {
            heading: "The Home Screen",
            text: "On this screen your will see all your created gardens and fertilizers."+
                  "Here you will also be able to create those."
        },
        newGarden: {
            heading: "The Creation Menu",
            text1: "If you press on the button in the middle of your screen this menu will open."+
                    "In this menu you can create a new garden by filling in the required fields. Your Garden needs a Title, inour case this will be ",
            name: "Herbfield",
            text2: ". The Length and Width of the Garden are also necessary. In Our case those will be 4 x 2 meters. The last thing is the square length. This field describes the area of one square later in our garden view. "+
                    "We put 2 in here. That means we will have 2 squares in Our Garden which will be 2x2 meters each in representaion. If we would like more squares for a more specific split we could leave it at 1."+
                    "This field is especially useful for bigger fields. The only thing you have to take in account is that both, the length and the width, have to be divisable by the square length.",
            text3: "If we then press Create under the squre length field, we are creating our first garden 🏡",
            text4: "In the same menu we can create a fertilzer, which we can later use. Just open up the menu, give your fertilizer a name, select the type of the fertilizer and press create."
        },
        home_creation: {
            heading:"Home with Garden and Fertilizer",
            text:   "Now we see on our homescreen that we created a garden with the name Herbfield and a fertilizer with the name Standard Fertilizer. "+
                    "If we would like we could now create even more gardens. But for now we will stick to this one. Lets open the garden by pressing on the title of the garden.",
        },
        garden: {
            heading: "The Garden",
            text: "On this Menu we can now see the two Sqaures we created and some information about our field on the top. At the bottom we see a small panel at which we can choose the right type for our squares. In our case we click on herbs, because we want to create an herb garden and click on the squares."
        },
        garden_with_types: {
            heading: "The Types",
            text: "The fields we clicked on have now changed their color to green. If we wanted to plant some vegetables we could have selected vegetables in bottom navigation, pressed on the squares and they would become purple. Now our fields have the type of herbs. For the next step we want to select the plant we want to grow on this tile. For this we press on the book icon under the image of your square.",
        },
        garden_menu: {
            heading: "The garden menu",
            text: "Now we want to select a plant. That we can just do by clicking on the selector and choosing the plant we want. Also we can choose the fertilizer, which we created, for our field. IN our case we choose Mint and for the other Square we choose Basil. On the reminder section we cloud also create a reminder for our square. For this we just select the desired values and press save.",
        },
        finished_garden: {
            heading: "Finished Garden",
            text: "Now we see in our Garden that the squares have Herb we selected and the fertilizer we created assigned to them. If we like we also could change those anytime.",
        },
        ending: {
            heading: "Ending a Season",
            text: "If the season is ending and we harvest our herb or vegetable bet and want to for example compare different fertilizer with each other, we could end the season with our yield amount. For this we just have to press on the End Season button.",
            text2: "For a comparisson of our fields, yields and used fertilizers we can open the main menu and press on Season endings.",
        }
    },
    de: {
        heading: {
            value: "Lernprogramm"
        },
        subheading: {
            text: "In diesem Tutorial gehen wir durch den Erstellungsprozess, einen Garten und Dünger zu erstellen, den Garten zu organisieren und Pflanzen auszuwählen. Wir werden dies am Beispiel eines Kräutergartens machen."
        },
        home: {
            heading: "Der Home Bildschirm",
            text: "Auf diesem Bildschrim sehen sie alle von ihnen angelegten Gäreten und Dünger. "+
                  "Ebenfalls können wir diese hier erstellen."
        },
        newGarden: {
            heading: "The Creation Menu",
            text1: "Wenn sie auf den grünen Plus Button in der Mitte ihres Bildschirms drücken öffnet sich das gezeigte Menü."+
                    "In diesem Menü können Sie einen neuen Garten anlegen, indem Sie die erforderlichen Felder ausfüllen. Ihr Garten braucht einen Titel, in unserem Fall ist das ",
            name: "Herbfield",
            text2: ". Die Länge und Breite des Gartens sind ebenfalls erforderlich. In unserem Fall werden diese 4 x 2 Meter sein. Der letzte Punkt ist die Quadratlänge. Dieses Feld beschreibt die Fläche eines Quadrats in unserer späteren Gartenansicht. "+
                    "Hier setzen wir 2 ein. Das bedeutet, dass wir 2 Quadrate in unserem Garten haben werden, die jeweils 2x2 Meter groß sind. Wenn wir mehr Quadrate für eine spezifischere Aufteilung möchten, können wir es bei 1 belassen oder sogar auf 0.5 Meter reduzieren."+
                    "Dieses Feld ist besonders bei größeren Feldern sinnvoll. Das einzige, was Sie beachten müssen, ist, dass sowohl die Länge als auch die Breite durch die Feldlänge teilbar sein müssen.",
            text3: "Wenn wir dann unter dem Feld für die Quadratlänge auf Erstellen drücken, legen wir unseren ersten Garten an 🏡",
            text4: "Im gleichen Menü können wir einen Dünger erstellen, den wir später verwenden können. Öffnen Sie einfach das Menü, geben Sie Ihrem Dünger einen Namen, wählen Sie den Typ des Düngers aus und drücken Sie auf Erstellen."
        },
        home_creation: {
            heading:"Home mit Garten und Dünger",
            text:   "Nun sehen wir auf unserem Homebildschirm, dass wir einen Garten mit dem Namen Herbfield und einen Dünger mit dem Namen Standard Fertilizer angelegt haben. "+
                    "Wenn wir möchten, könnten wir auch noch mehr Gärten erstellen. Für den Moment bleiben wir bei diesem einen. Wir öffnen den Garten, indem wir auf den Titel des Gartens klicken.",
        },
        garden: {
            heading: "Der Garten",
            text: "In diesem Menü sehen wir nun die beiden von uns erstellten Quadrate und einige Informationen über unser Feld. Unten sehen wir ein kleines Panel, in dem wir den geeigneten Typ für unsere Quadrate auswählen können. In unserem Fall klicken wir auf Kräuter, weil wir einen Kräutergarten anlegen wollen und klicken nach dem Auswählen auf die Quadrate."
        },
        garden_with_types: {
            heading: "Die Typen",
            text: "Die Felder, die wir angeklickt haben, erscheinen nun grün. Wenn wir Gemüse anpflanzen wollten, hätten wir in der unteren Navigation Gemüse ausgewählt, auf die Felder gedrückt und sie würden lila erscheinen. Jetzt haben unsere Felder den Typ Kräuter. Im nächsten Schritt wollen wir die Pflanze auswählen, die wir auf diesem Feld anbauen wollen. Dazu drücken wir auf das Buchsymbol unter dem Bild unseres Feldes.",
        },
        garden_menu: {
            heading: "Das Garten Menu",
            text: "Nun wollen wir eine Pflanze auswählen. Das können wir machen, indem wir auf den Selektor klicken und die gewünschte Pflanze auswählen. Auch können wir den Dünger, den wir erstellt haben, für unser Feld auswählen. In unserem Fall wählen wir Minze und für den anderen Platz wählen wir Basilikum aus. In der Sektion Erinnerung können wir auch eine Erinnerung für unser Feld erstellen. Dazu wählen wir einfach die gewünschten Werte aus und drücken auf Speichern.",
        },
        finished_garden: {
            heading: "Fertiger Garten",
            text: "Jetzt sehen wir in unserem Garten, dass den Quadraten das von uns ausgewählte Kraut und der von uns erstellte Dünger zugewiesen wurden. Wenn wir möchten, können wir diese jederzeit ändern.",
        },
        ending: {
            heading: "Beenden einer Saison",
            text: "Wenn die Saison zu Ende ist und wir unsere Kräuter- oder Gemüsebeete ernten und z.B. verschiedene Dünger miteinander vergleichen wollen, können wir die Saison mit unserer zugehörigen Ertragsmenge beenden. Dazu müssen wir nur auf die Schaltfläche Saison beenden drücken.",
            text2: "Für einen Vergleich unserer Felder, Erträge und der eingesetzten Dünger können wir das Hauptmenü öffnen und auf Saisonende drücken. Dort werden diese dann aufgelistet.",
        }
    }
}