/*
In this file is all the auth that is required in the Square Garden application
*/
import db from "./init.js"
import { auth } from "./init.js";
import router from "../router/index";
import store from "../store/index";

//old code --> doesn't work as expected 
export function isAuthenticated() {  
    //is not valid on refresh and on coldstart
    const user = auth.currentUser;
    console.log(user)  
    if (user) {  
        return true;  
    }  
    else {  
        return false;
    }  
}

//signup with email and password
export function createAccountWithEmail(email, password) {
    //signup user in firebase
    auth.createUserWithEmailAndPassword(email, password)
        .then(credentials => {
            db.collection("Users").doc(credentials.user.uid).set({
              email
            })
            .then(() => {
                //sending verification email
                auth.currentUser.sendEmailVerification()
                    .then(() => {
                        console.log("email send")
                        return "success"
                    })
                    .catch(err => {
                        console.log(err)
                        return err});
                console.log("user saved!");
                router.push("/dashboard");
            }).catch(err => {
                console.log("error creating User: ", err);
            })
        })
        .catch(err => {
            return err.message;
        })
};

//Logout Method
export function logout() {
    auth.signOut().then(function() {
        //this redfirects to login in the router
        //directly putting the use to null state for the router, so we dont have to wait for the onAuthChange Callback
        store.dispatch("user/change_auth_status", null);
        router.push("/login");
    }, err => {
        console.log(err.type, err.message);
    })
}


//Login Method
export async function loginWithEmail(email, password){
    try {
        const credentails = await auth.signInWithEmailAndPassword(email, password);
        return {type: "success", "message": credentails.user.uid};
    }catch(err) {
        return {type: "error", message: err.message };
    }
};

//Function to send a verification e-mail to the user
/*function sendVerificationEmail(user){
    user.sendEmailVerification()
        .then(() => {
            console.log("email send")
            return "success"
        })
        .catch(err => {
            console.log(err)
            return err});
}*/