export default {
    en: {
        heading: { 
            value: "New Garden"
        },
        gardenForm: {
            title: "Garden Title 🏡",
            length: "Length 📏",
            width: "Width 📏",
            division: "Square Length 🔲",
            titleExample: "e.g. Herb Garden",
            valueExample: "e.g. 5m",
        },
        fertilizerForm: {
            title: "Fertilizer 🐛",
            titleExample: "e.g. universal Fertilizer",
            typeSelection: "Select a type",
        }
    },
    de: {
        heading: { 
            value: "Neuer Garten"
        },
        gardenForm: {
            title: "Gartenname 🏡",
            length: "Länge 📏",
            width: "Breite 📏",
            division: "Feldlänge 🔲",
            titleExample: "z.B. Kräuter Garten",
            valueExample: "z.B. 5m",
        },
        fertilizerForm: {
            title: "Dünger 🐛",
            titleExample: "z.B. Universaldünger",
            typeSelection: "Typ auswählen"
        }
    }
}