export default {
    en: {
        hero: { 
            heading: "Register",
            text:"Want to use Square Garden, Register now!",
        },
        email: { 
            value: "Email address"
        },
        password: { 
            value: "Password",
            repeat: "Repeat password"
        },
        button: { 
            value: "Sign Up"
        }
    },
    de: {
        hero: { 
            heading: "Registierung",
            text:"Registrieren sich sich jetzt um Square Garden verwenden zu können!",
        },
        email: { 
            value: "Email Adresse"
        },
        password: { 
            value: "Passwort",
            repeat: "Passwort wiederholen"
        },
        button: { 
            value: "Registrieren"
        }
    }
}