export default {
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },
    created() {
        //once allows the listener to be called once and removes the listener once invoked
        document.addEventListener("swUpdated", this.updateAvailable, {once: true});

        //Prevent multiple refreshes
        //Reloading the page -> listening for the controllerchange event
        navigator.serviceWorker.addEventListener("controllerchange", () => {
            //Check if data is refreshing -> if so do nothing
            if(this.refreshing) return
            this.refreshing = true;
            //Here the actual reload of the page occurs 
            window.location.reload();
        })
    },
    methods: {
        updateAvailable(event){
            this.registration = event.detail;
            this.updateExists = true;
        },
        refreshApp() {
            this.updateExists = false;
            //Making sure we only send a skip waiting message is the SW is waiting
            if( !this.registration || !this.registration.waiting) return;
            //Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
        },
    },
}