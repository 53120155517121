import { register } from 'register-service-worker'

let installPromptEvent;

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      //console.log("Service Worker ready!");
      //Notification -> for this we wil use the notification API
      //The Notification API has a few methods, one is the requestPermission
      /*
      //Service workers are even running if the application is closed -> thats why pushnotifications work
      Push Notifications work in 2 steps:
      1. The Website can push information
      2. something in the service worker has be taking that information and push it over to the application
      */
      Notification.requestPermission(function(status) {
        console.log("Thank you for activating Push Notifications!");
        //console.log("Permission status: ", status);
      });

      //navigator.geolocation => to ask for permission for geolocation
      //use getCurrentPosition and watchPosition to get the position of the User
    },
    registered () {
    },
    cached () {
      //console.log("cached assets")
    },
    updatefound () {
      //console.log("New Content is available!");
    },
    //In updated we handle that a new service worker is automatically installed when ready
    updated (registration) {
      console.log("Huray new Content 🌻");
      //We are triggering an Event called swUpdated and sending the ServiceWorkerRegistration as a property
      document.dispatchEvent(
        new CustomEvent("swUpdated", {detail: registration})
      )
    },
    offline () {
      console.log("You are offline!");
    },
    error(err) {
      console.log(err);
    }
  });
   
  /*
  *  INSTALLATION EVENT
  */

  window.addEventListener("beforeinstallprompt", e => {
    //prevent the mini-infobar from appering on mobile
    e.preventDefault();

    //When system is iOS
    if(detectIOS()){
      installPromptEvent = e;
      const iOSInstall = document.getElementById("iOSInstall");
      if(iOSInstall){
        showIosInstall(iOSInstall);   
      }
    } 
    // Android
    else {
      //Stash the event so it can be triggered later
      installPromptEvent = e;
      //Update UI to notify the user they can install the PWA
      showInstallPromotion();
    }
  });

  /*
  *   Listening if the App was installed by the User
  */
  //Listen for the appinstalled event. It is fired whenever your PWA is installed, no matter what mechanism is used to install th PWA!
  window.addEventListener("appinstalled", e => {
    //hide the install buttons on the side if PWA got installed any other way!
    const installButton = document.getElementById("installbutton");
    const iOSInstall = document.getElementById("iOSInstall")
    if(installButton) {
      installButton.classList.add("hide");
      //dismissing the installPromptEvent
      installPromptEvent = null;
      //Optionally send analytics
      console.log("PWA was installed!");
    }
    if(iOSInstall){
      iOSInstall.classList.add("hide");
      installPromptEvent = null;
    }
  })
}

function showInstallPromotion() {
  const installButton = document.getElementById("installbutton");
  //on some Sites the Installbutton is not available
  if(installButton) {
    installButton.classList.remove("hide");
  
    //adding the event listener for the installButton
    installButton.addEventListener("click", async () => {
      //hiding the Install Promotion
      installButton.classList.add("hide");
      //showing the install prompt
      installPromptEvent.prompt();

      //Wait for Userinteraction
      const { outcome } = await installPromptEvent.userChoice;

      //Optionally send response to analytics with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);
      //deleting the event
      installPromptEvent = null;
    })
  }
}

/* iOS installation */

/* When on iOS -> press share and than add to HomeScreen */
function showIosInstall(iOSInstall) {
  iOSInstall.classList.remove("hide");
}

// Detects if device is on iOS
function detectIOS() {
    const isIos = () => {
      const os = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(os);
    }

    //Check if standalone mode
    const isInStandaloneMode = () => (navigator.standalone === true)  && (window.navigator.standalone);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      return true;
    } else {
      return false;
    }
}

//TODO: mache etwas damit

/*
*   By detecting how the app was launched you can differ styles and so on 
*/
function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if (navigator.standalone || isStandalone) {
    //because safari doesn't support matchMedia right now
    return 'standalone';
  }
  return 'browser';
}

/*
//To track if the user changes between standalone, and browser tab, listen for changes to the display-mode media query.
//-> switch displaymode
window.matchMedia('(display-mode: standalone)').addEventListener((evt) => {
  let displayMode = 'browser';
  if (evt.matches) {
    displayMode = 'standalone';
  }
  // Log display mode change to analytics
  console.log('DISPLAY_MODE_CHANGED', displayMode);
});
*/



//Applying styles for different launch modes --> updating apps icon and name for example
/*
@media all and (display-mode: standalone) {
  body {
    background-color: yellow;
  }
}
*/