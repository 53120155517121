export default {
    en: {
        hero: { 
            heading: "Login",
            text:"Login now to use Square Garden!",
        },
        email: { 
            value: "Email address"
        },
        password: { 
            value: "Password",
        },
        button: { 
            value: "Login"
        }
    },
    de: {
        hero: { 
            heading: "Login",
            text:"Jetzt einloggen um Square Garden zu verwenden!",
        },
        email: { 
            value: "Email Adresse"
        },
        password: { 
            value: "Passwort",
        },
        button: { 
            value: "Login"
        }
    }
}