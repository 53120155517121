export default {
    namespaced: true,
    state: {
        //The currently logged in user
        /*reminderTitle: "",
        reminderType: "",
        reminderAmountWeekly: 1,
        reminderAmountMonthly: 1,*/
        times: [],
        saveProps: false,
    },
    mutations: {
        /*update_title(state, title) {
            state.reminderTitle = title;
        },
        update_type(state, type) {
            state.reminderTitle = type;
        },
        update_amount_weekly(state, weekly) {
            state.reminderTitle = weekly;
        },
        update_amount_monthly(state, monthly) {
            state.reminderTitle = monthly;
        },*/
        update_times(state, times) {
            state.times = times;
        },
        update_props(state, prop) {
            state.saveProps = prop;
        }
    },
    actions: {
        /*change_title({ commit }, title) {
            commit("update_title", title);
        },
        change_type({ commit }, type) {
            commit("update_title", type);
        },
        change_amount_weekly({ commit }, weekly) {
            commit("update_title", weekly);
        },
        change_amount_monthly({ commit }, monthly) {
            commit("update_title", monthly);
        },*/
        change_times({ commit }, times) {
            commit("update_times", times);
        },
        change_props({ commit }, prop){
            commit("update_props", prop)
        }
    },
    getters: {
        /*getTitle: (state) => {
            return state.reminderTitle
        },
        getType: (state) => {
            return state.reminderType
        },
        getWeekly: (state) => {
            return state.reminderAmountWeekly
        },
        getMonthly: (state) => {
            return state.reminderAmountMonthly
        },*/
        getTimes: (state) => {
            return state.times
        },
        getProps: (state) => {
            return state.saveProps
        }
    }
}