<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
import { auth } from "./db/init.js";
import swUpdate from "./mixins/udpateSW.js";

export default {
  beforeMount() {
    /**
     * Checks if the User is already loged in and if so redirects to the Homepage
     */
    auth.onAuthStateChanged((user) => {
      this.$store.dispatch("user/change_auth_status", user);
    });
  },
  watch: {
    updateExists: function () {
      if (this.updateExists) {
        this.$buefy.snackbar.open({
          indefinite: true,
          message: "It is an update available for this App!",
          type: "is-info",
          actionText: "Update now!",
          indefinite: true,
          onAction: () => {
            this.refreshApp();
          },
        });
      }
    },
  },
  mixins: [swUpdate],
};
</script>

<style>
body {
  background: #b1d8b7;
}

.app {
  background: #b1d8b7;
  width: 100%;
  min-height: 100vh;
}
</style>
