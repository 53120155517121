export default {
    en: {
        infoCard: { 
            title: "Title",
            dimension: "Dimension",
            length: "Square length"
        },
        seasonEnd: {
            button: "End Season",
        },
        gardenTile: {
            fertilizer: "Fertilzer",
            noFertilizer: "No Fertilzer"
        },
        typeMenu: {
            fruits: "Fruits",
            herbs: "Herbs",
            flowers: "Flowers",
            trees: "Trees",
            vegetables: "Vegetables",
            succulents: "Succulents",
            bushes: "Bushes",
            delete: "Delete",
        },
        menu: {
            menu: {
                heading: "Field Menu",
                plantSpec: "Select a plant",
                current: "Current plant",
                placeholder: "Select plant",
                others: "✏ Others",
                otherSpec: "Name of the plant",
            },
            fertilizer: {
                heading: "Fertilizer",
                plantSpec: "Select Fertilizer",
                current: "Current ferilizer",
                placeholder: "Select a fertilizer",
                noFertilizer: "No Fertilizer"
            },
            reminder: {
                heading: "Reminder",
                remindAmount: "How often you want to get reminded? 📱",
                amountPlaceholder: "Select how often",
                per: "per",
                intervalPlaceholder: "Interval",
                title: "Remindername",
                titlePlaceholder: "Fertilizer reminder",
                reminderType: "Choose a reminder type",
                reminderTypeOptions: {
                    daily: "Daily",
                    weekly: "Weekly",
                    monthly: "Monthly",
                },
            },
            save: {
                button: "Save"
            }
        }
    },
    de: {
        infoCard: { 
            title: "Titel",
            dimension: "Größe",
            length: "Feldlänge"
        },
        seasonEnd: {
            button: "Saison beenden",
        },
        gardenTile: {
            fertilizer: "Dünger",
            noFertilizer: "Kein Dünger",
        },
        typeMenu: {
            fruits: "Früchte",
            herbs: "Kräuter",
            flowers: "Blumen",
            trees: "Bäume",
            vegetables: "Gemüse",
            succulents: "Sukkulenten",
            bushes: "Sträucher",
            delete: "Löschen",
        },
        menu: {
            menu: {
                heading: "Feld Menu",
                plantSpec: "Pflanze auswählen",
                current: "Akutelle Pflanze",
                placeholder: "Pflanze auswählen",
                others: "✏ Andere",
                otherSpec: "Name der Pflanze",
            },
            fertilizer: {
                heading: "Dünger",
                plantSpec: "Dünger auswählen",
                current: "Aktueller Dünger",
                placeholder: "Dünger auswählen",
                noFertilizer: "Kein Dünger",
            },
            reminder: {
                heading: "Erinnerung",
                remindAmount: "Wie oft möchten sie erinnert werden? 📱",
                amountPlaceholder: "Anzahl auswählen",
                per: "pro",
                intervalPlaceholder: "Intervall",
                title: "Erinnerungsname",
                titlePlaceholder: "Düngererinnerung",
                reminderType: "Wählen sie ein Zeitintervall",
                reminderTypeOptions: {
                    daily: "Täglich",
                    weekly: "Wöchentlich",
                    monthly: "Monatlich"
                },
            },
            save: {
                button: "Speichern"
            },
        }
    }
}