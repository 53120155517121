export default {
    en: {
        heading: { 
            value: "Your Gardens"
        },
    },
    de: {
        heading: { 
            value: "Deine Gärten"
        },
    }
}