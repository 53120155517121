export default {
    en: { 
        welcomeMessage: { 
            value: "Welcome to Square Garden, the way to organize and structure your gardening! 🌱"
        }
    },
    de: {
        welcomeMessage: {
            value: 'Willkommen bei Square Garden, der App die ihnen bei ihrer Gartenarbeit hilft! 🌱'
        }
    }
}