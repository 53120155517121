export default {
    namespaced: true,
    state: {
        //The last saved position
        geoPosition: {
            lat: 0,
            lon: 0,
        },
        homePosition: {
            lat: 0,
            lon: 0,
        },
        watchStatus: false,
        weatherFunctionality: false,
    },
    mutations: {
        UPDATE_POSITION(state, newPosition) {
            state.geoPosition["lat"] = newPosition.lat;
            state.geoPosition["lon"] = newPosition.lon;
        },
        UPDATE_DELETE_POSITION (state){
            state.geoPosition["lat"] = 0;
            state.geoPosition["lon"] = 0;
        },
        UPDATE_HOME_POSITION(state, newPosition) {
            state.homePosition["lat"] = newPosition.lat;
            state.homePosition["lon"] = newPosition.lon;
        },
        UPDATE_WATCH_STATUS(state){
            state.watchStatus = !state.watchStatus;
        },
        UPDATE_WEATHER_FUNCTIONALITY(state) {
            state.weatherFunctionality = !state.weatherFunctionality;
        }
    },
    actions: {
        change_geo_position({ commit }, geo_position) {
            commit("UPDATE_POSITION", geo_position);
        },
        change_home_position({ commit }, home_position) {
            commit("UPDATE_HOME_POSITION", home_position);
        },
        change_watch_status({ commit }){
            commit("UPDATE_WATCH_STATUS");
        },
        change_weather_functionality({ commit }){
            commit("UPDATE_WEATHER_FUNCTIONALITY");
        }
    },
    getters: {
        getLatestGeoPosition: (state) => {
            return state.geoPosition
        },
        getHomePosition: (state) => {
            return state.homePosition
        },
        getWatchStatus: (state) => {
            return state.watchStatus
        },
        getWeatherFuncStatus: (state) => {
            return state.weatherFunctionality
        }
    },
    setters: {
        changeWatchStatus(state) {
            state.watchStatus = !state.watchStatus;
        },
        changeWeatherFuncStatus: (state) => {
           state.weatherFunctionality = !state.watchStatus;
        }
    }
}